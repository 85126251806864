<script>
import { Radio, Button, Row, Col, Checkbox } from 'ant-design-vue'
export default {
  props: {
    typeData: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  mounted() {
    if (this.item.defaultValue !== undefined) {
      this.$emit('change', this.item.defaultValue)
    }
  },
  methods: {
    renderRadioButton(item) {
      return (
        <div class="radio-item">
          <Button
            style={{
              marginRight: '10px',
              marginTop: '3px',
              padding: '0px 5px',
              width: 'fit-content',
              ...item.styles,
              ...this.item.styles,
            }}
          >
            <Radio value={item.value} disabled={item.disabled}>
              {item.name}
            </Radio>
          </Button>
          {item.hint && <p>{item.hint}</p>}
        </div>
      )
    },
    renderCheckButton(item) {
      return (
        <Button style={{ marginRight: '10px', marginTop: '3px', ...this.item.styles }}>
          <Checkbox disabled={item.disabled} value={item.value} onClick={() => this.onCheckItem(item)}>
            {item.name}
          </Checkbox>
        </Button>
      )
    },
    onCheckItem(item) {
      let arr = this.value ? this.value.split(',') : []
      ;(this.typeData || [])
        .filter((e) => e.type === 'radio')
        .map((e) => {
          if (arr.includes(e.value)) arr = []
        })
      if (arr.includes(item.value)) {
        arr.splice(arr.indexOf(item.value), 1)
      } else {
        arr.push(item.value)
      }
      this.$emit('change', arr.toString())
    },
    onChange(value) {
      if (this.item.onChange) {
        this.item.onChange(value)
      }
      this.$emit('change', value)
    },
  },
  render() {
    return (
      <div class="box">
        <Checkbox.Group
          disabled={this.mode == 'detail' || this.item.disabled}
          value={this.value.split(',')}
          style={{ display: 'flex', height: '100%' }}
        >
          {(this.typeData || []).filter((e) => e.type === 'check').map((e) => this.renderCheckButton(e))}
        </Checkbox.Group>
        <Radio.Group
          disabled={this.mode == 'detail' || this.item.disabled}
          value={this.value}
          onChange={(e) => this.onChange(e.target.value)}
          style={{ display: 'flex', height: '100%', flexWrap: 'wrap' }}
        >
          {(this.typeData || []).filter((e) => e.type === 'radio').map((e) => this.renderRadioButton(e))}
        </Radio.Group>
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.box {
  display: flex;
}
.radio-item {
  display: flex;
  flex-direction: column;
  p {
    display: inline-block;
    margin-top: 10px;
    margin-right: 12px;
    color: #b6b6b6;
  }
}
</style>
