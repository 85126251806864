import moment from 'moment'

export default ({ data }) => {
  return {
    title: '',
    form: [
      {
        type: 'textGroup',
        value: [
          {
            name: '收件人',
            value: data.pickupPersonName,
          },
          {
            name: '收货人电话',
            value: data.pickupPersonPhone,
          },
          {
            name: '收货时间',
            value: data.pickupTime ? moment(data.pickupTime).format('yyyy-MM-DD') : '',
          },
        ].filter((e) => e.value),
      },
    ],
  }
}
