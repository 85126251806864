<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction, downloadAction, getParams } from '@/command/netTool'
import orderConfig from '../../../orderConfig'
import apiTool from '@/command/apiTool'
import { orderStateList, pickupTypeList, productTypeSpecialtyList } from '@/utils/textFile'
import { Modal } from 'ant-design-vue'
export default {
  name: 'localProducts',
  data() {
    return {
      ...api.command.getState(),
      courierData: [],
      orderItemListId: null,
    }
  },
  activated() {
    const { status, userTeamId } = this.$route.query
    if (status || userTeamId) {
      this.getList(status, userTeamId)
    }
  },
  mounted() {
    const { status, userTeamId } = this.$route.query
    this.getList(status, userTeamId)
    // 获取收货方式列表
    this.getCourier()
  },
  methods: {
    getList(status, userTeamId) {
      api.command.getList.call(this, {
        url: '/productAppointment/order/page',
        current: 1,
        params: { status, userTeamId },
      })
    },
    getCourier() {
      getAction('/api/base/express').then((result) => {
        this.courierData = result.data.map((e) => {
          return {
            ...e,
            value: e.code,
          }
        })
      })
    },
    // 获取选中订单发票详情
    getInvoiceDetailData(id) {
      return new Promise((resolve, reject) => {
        getAction(`/farmOrderInvoice/queryDetailByOrderId?orderId=${id}`)
          .then((result) => {
            resolve(result.data)
          })
          .catch((e) => reject && reject(e))
      })
    },
    // 获取选中订单详情
    getDetailData(id) {
      return new Promise((resolve, reject) => {
        getAction(`/api/product/order/detail?id=${id}`)
          .then((result) => {
            resolve({
              ...result.data,
              valCopy:
                result.data.deliveryList && result.data.deliveryList.length > 0 ? result.data.deliveryList[0] : {},
            })
          })
          .catch((e) => reject && reject(e))
      })
    },
    //开票信息
    onInvoice(records) {
      const ext = [
        // 基本信息
        orderConfig.invoices,
        // 接收信息
        orderConfig.invoicesAccept,
      ]
      this.getInvoiceDetailData(records.id).then((e) => {
        orderConfig.open({
          title: '开票信息',
          data: e,
          params: { data: e },
          executeFun: ext,
        })
      })
    },
    openModal(form = {}, setHidden) {
      apiTool.showModal({
        width: '500px',
        title: '收货人',
        formData: {},
        success: ({ data }) => {
          api.order.pickedUp
            .call(this, { url: '/product/order/receivingPickup', params: { id: form.id, ...data } })
            .then(setHidden)
        },
        form: (data) => {
          return [
            {
              type: 'input',
              name: '收货人',
              key: 'pickupPersonName',
              maxLength: 40,
              cols: 24,
              rules: [
                {
                  required: true,
                },
              ],
            },
            {
              type: 'input',
              name: '手机号',
              key: 'pickupPersonPhone',
              maxLength: 40,
              cols: 24,
              rules: [
                {
                  required: true,
                },
              ],
            },
            {
              name: '收货时间',
              type: 'datePicker',
              key: 'pickupTime',
              maxLength: 40,
              cols: 24,
              props: {
                valueFormat: 'YYYY-MM-DD HH:mm:ss',
              },
              rules: [
                {
                  required: true,
                },
              ],
            },
          ].filter((e) => (e.display == undefined ? true : e.display))
        },
      })
    },
    // 已取货
    onPickUp(data) {
      this.getDetailData(data.id).then((result) => {
        data = result

        orderConfig.puikUp({
          data,
          params: {
            data,
            typeData: {
              courierData: this.courierData,
            },
            type: 'specialty',
          },
          success: ({ data, setHidden }) => {
            this.openModal(data, setHidden)
          },
        })
      })
    },
    // 待发货
    onDelivery(data) {
      this.getDetailData(data.id).then((result) => {
        data = result

        orderConfig.delivery({
          data,
          params: {
            data,
            typeData: {
              courierData: this.courierData,
            },
            type: 'specialty',
          },
          success: ({ data, setHidden }) => {
            if (data.deliveryType == '0') {
              if (data.deliveryCode) {
                data.deliveryCompany = this.courierData.find((e) => e.value == data.deliveryCode).name
              } else {
                this.$message.warning('请选择发货方式！')
                return
              }
            }
            if (data.deliverySn) {
              data.deliverySn = data.deliverySn.replace(/\s*/g, '')
            }
            api.order.delivery.call(this, { url: '/product/order/shipping', params: data }).then(setHidden)
          },
        })
      })
    },
    getHeader() {
      return [
        {
          name: '订单编号',
          type: 'input',
          key: 'orderSn',
          placeholder: '请输入订单编号',
        },
        {
          name: '出售商品',
          type: 'input',
          key: 'productName',
        },
        {
          name: '收货人电话',
          type: 'input',
          key: 'buyPhone',
          placeholder: '请输入收货人电话',
        },
        {
          name: '类型',
          type: 'select',
          key: 'pickupType',
          typeData: pickupTypeList,
        },
        {
          name: '收货人',
          type: 'input',
          key: 'buyName',
        },
        {
          name: '下单时间',
          type: 'rangePicker',
          keys: ['startTime', 'endTime'],
          showTimeNo: true,
        },
        {
          name: '店铺名称',
          type: 'input',
          key: 'shopName',
        },
        {
          name: '订单状态',
          type: 'select',
          key: 'status',
          typeData: orderStateList
            .filter((e) => !['0', '1'].includes(e.value))
            .map((e) => ({
              ...e,
              name: e.text,
            })),
        },
      ]
    },
    // 关闭订单事件
    onCloseOrder(records) {
      api.order.close.call(this, {
        url: '/common/order/close',
        params: { id: records.id },
      })
    },
    // 退款事件
    onRefund({ records, item }) {
      let that = this
      orderConfig.refund({
        data: records,
        params: { data: records, childrenId: this.orderItemListId },
        success({ data, setHidden }) {
          api.order.refund
            .call(that, {
              url: '/product/order/refund',
              params: { ...item, itemId: item.id, ...data },
            })
            .then(setHidden)
        },
      })
    },
    getColumnsButtonData({ parent, item, index, childrenId } = {}) {
      if (index == 0) {
        let arr = [
          {
            name: '关闭订单',
            display: parent.status == '2',
            type: 'pop',
            popTitle: '确定关闭订单吗',
            // onClick: () => this.getDetailData(parent.id).then((e) => this.payment(e)),
            onClick: () => {
              api.order.cancelOrderNativeProduct
                .call(this, {
                  url: '/api/productAppointment/order/cancelOrderNativeProduct',
                  params: { orderId: parent.id },
                })
                .then()
            },
          },
          {
            name: '收货',
            display: parent.status == '3',
            onClick: () => this.getDetailData(parent.id).then(this.received),
          },
          {
            name: '发货',
            display: (parent.status == '2' || parent.status == '3') && item.returnStatus != '2' && !parent.pickUpId,
            onClick: () => this.onDelivery(parent),
          },
          {
            name: '已取货',
            display: (parent.status == '2' || parent.status == '3') && item.returnStatus != '2' && parent.pickUpId,
            type: 'pop',
            popTitle: '确定已取货吗',
            onClick: () => {
              this.onPickUp(parent)
              //  api.order.close
              //   .call(this, {
              //     url: '/common/order/close',
              //     params: { id: parent.id },
              //   })
              //   .then()
            },
          },
          {
            name: '详情',
            display: true,
            onClick: () => {
              this.onSelectRow(parent)
            },
          },
        ]
        if (process.env.NODE_ENV === 'development') {
          return arr.filter((e) => e.display)
        } else {
          const buttonName = apiTool.getButtonName()
          return arr.filter((e) => e.display && buttonName.some((el) => e.name.indexOf(el) !== -1))
        }
      }
    },
    renderColumnButton({ orderItem, item }) {
      return (
        <a
          style={{ marginRight: '10px' }}
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            item.onClick(orderItem)
          }}
        >
          {item.name}
        </a>
      )
    },

    getColumns() {
      let that = this
      return [
        {
          dataIndex: 'orderSn',
          title: '单号编号',
          isId: true,
          align: 'left',
          sorter: (a, b) => a.orderSn - b.orderSn,
        },
        {
          dataIndex: 'productName',
          title: '出售商品',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                {records.orderItemList.map((e) => (
                  <div class="product-order-item">
                    <img src={e.productPic} />
                    <div>{e.productName}</div>
                  </div>
                ))}
              </div>
            )
          },
        },
        {
          dataIndex: 'shopName',
          title: '店铺名称',
          align: 'left',
        },
        {
          dataIndex: 'productQuantity',
          title: '数量',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-number">
                {records.orderItemList.map((e) => {
                  return <div>{e.productQuantity}</div>
                })}
              </div>
            )
          },
        },
        {
          dataIndex: 'totalAmount',
          title: '订单金额',
          align: 'left',
          customRender: (text, records) => {
            return <div>{records.totalAmount.toFixed(2)}</div>
          },
        },
        {
          dataIndex: 'payAmount',
          title: '实付金额',
          align: 'left',
          customRender: (text, records) => {
            return <div>{records.payAmount.toFixed(2)}</div>
          },
        },
        {
          dataIndex: 'receiverName',
          title: '收货人',
          align: 'left',
        },
        {
          dataIndex: 'receiverPhone',
          title: '电话',
          align: 'left',
        },
        {
          dataIndex: 'createTime',
          title: '下单时间',
          align: 'left',
        },
        {
          dataIndex: 'status',
          title: '订单状态',
          align: 'left',
          customRender: (text, record) => {
            if (record.pickUpId && text == 2) {
              return '待取货'
            } else {
              return (orderStateList.find((e) => e.value == text) || { text: '' }).text
            }
          },
          // onExport: (text) => (orderStateList.find((e) => e.value == text) || { text: '' }).text,
          // filterMultiple: true,
          // filters: orderStateList,
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          width: '10%',
          customRender: function customRender(text, records) {
            return (
              <div
                class="actionBox"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                {records.orderItemList.map((e, index) => {
                  // 获取对应button按钮 子订单需要判断是否有退款
                  const data = that.getColumnsButtonData({
                    parent: records,
                    item: e,
                    index,
                    childrenId: e.id,
                  })
                  return (
                    data && (
                      <div class="product-order-item">
                        {data.map((el) => that.renderColumnButton({ orderItem: e, item: el }))}
                      </div>
                    )
                  )
                })}
              </div>
            )
          },
        },
      ]
    },
    // 批量删除
    onBatchDelete(keys) {
      return api.order.batchClose.call(this, {
        url: '/common/order/closeBatch',
        params: { idList: keys.map((e) => e.id) },
      })
    },
    getButton() {
      return [
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量关闭',
              onClick: this.onBatchDelete,
            },
          ],
        },
      ]
    },
    // 已关闭
    completed(data) {
      orderConfig.completed({
        data,
        params: {
          data,
          typeData: {
            courierData: this.courierData,
          },
          type: 'specialty',
        },
        success: ({ data, setHidden }) => {
          Modal.confirm({
            title: '提示',
            content: '此操作将删除该订单, 是否继续？',
            onOk: () => {
              api.command.del
                .call(this, {
                  url: `/product/order/delete?id=${data.id}`,
                })
                .then((res) => {
                  setHidden()
                })
            },
            onCancel() {},
          })
        },
      })
    },
    // 确认收货
    received(data) {
      orderConfig.received({
        data,
        params: {
          data,
          typeData: {
            courierData: this.courierData,
          },
          type: 'specialty',
        },
        success: ({ data, setHidden }) => {
          api.order.received.call(this, { url: '/product/order/receiving', params: data }).then(setHidden)
        },
      })
    },
    // 支付
    payment(data) {
      orderConfig.close({
        data,
        params: {
          data,
          typeData: {
            courierData: this.courierData,
          },
          type: 'specialty',
        },
        success: ({ setHidden }) => {
          api.order.close
            .call(this, {
              url: '/common/order/close',
              params: { id: data.id },
            })
            .then(setHidden)
        },
      })
    },
    onSelectRow(records) {
      // 0->待支付；1->待使用；2->待发货；3->待签收；4->待评价；5->已完成；6->已关闭；
      switch (records.status) {
        case '6':
        case '5':
        case '4':
          this.getDetailData(records.id).then(records.pickUpId ? this.onPickUp : this.completed)
          break
        case '3':
          this.getDetailData(records.id).then(this.received)
          break
        case '2':
          this.getDetailData(records.id).then(records.pickUpId ? this.onPickUp : this.onDelivery)
          break
        case '0':
          this.getDetailData(records.id).then(this.payment)
          break
        default:
      }
    },
    getRightButton() {
      return [
        {
          name: '导出Excel',
          onClick: (ev, data) => {
            downloadAction(`/api/productAppointment/order/productExport${getParams(data)}`, '预约单.xlsx')
          },
          type: 'button',
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        rightButton={this.getRightButton()}
        records={this.records}
        onSelectRow={this.onSelectRow}
        filterRightButtonKey={['refresh', 'setting']}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 5px;
    object-fit: cover;
  }
}
.product-order-item:nth-child(1)::after {
  content: '';
  display: block;
  width: 11px;
  height: 11px;
  color: #999999;
}
.product-number {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  div {
    height: 30px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}
.table-foot {
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid rgba(232, 234, 236, 1);
    span {
      width: 150px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #515a6e;
    }
  }
}

.actionBox {
  .product-order-item {
    &:nth-of-type(2) {
      margin-left: 10px;
    }
    &:nth-child(1) {
      height: 20px;
      &::after {
        display: none;
      }
    }
  }
}
</style>
