<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import { putAction, getAction, postAction } from '@/command/netTool'
import apiTool from '@/command/apiTool'
import FormUploadSku from '@/components/FormView/FormUploadSku'
import FormProductStandard from './FormProductStandard'
import CardIconLabelLocal from '@/components/CardIconLabelLocal'
import { productTypeSpecialtyList } from '@/utils/textFile'

export default {
  name: 'specialtyDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        farmNativeProductStandardsVOS: [],
        upDown: '',
        salePrice: null,
        vipPrice: null,
        canBuy: '1',
        deliveryStatus: [],
        retailPriceMax: '',
        retailPriceMin: '',
        retailPrice: '',
        productType: '0',
        // nativePickupList: []
      },
      storeData: [],
      mallData: [],
      freightData: [],
      thirdProductData: [],
      thirdBatchData: [],
      treeData: [],
      goodsLocation: [],
      isShowSnapshot: false,
      classifyList: [],
      labelList: [],
      labelGroup: [],
      pickupAddressList: [],
    }
  },
  watch: {
    'detail.linkShopId'(a, b) {
      if (a) {
        this.getMainBody(a)
        this.thirdProductSearch()
        this.getBaseDataInit(a)
      }
    },
    'detail.thirdProductId'(a, b) {
      if (a) {
        this.thirdBatchSearch()
      }
    },
  },
  mounted() {
    getAction('/api/config/farmGoodsCategory/listTree').then((res) => {
      if (res.code == 200) {
        this.treeData = res.data
      } else {
        this.$message.warning(res.msg)
      }
    })
    const { id } = this.$route.query
    this.storeSearch()
    !id && this.mallSearch()
    this.freightSearch()
    const appCode = localStorage.getItem('appCode').toLocaleLowerCase()

    // 获取详情
    id &&
      api.command.getDetail.call(this, { url: `/commodity/farmNativeProduct/${id}` }).then((result) => {
        let arr = result.farmNativeProductStandardsVOS ?? []
        this.detail.farmNativeProductStandardsVOS = arr.map((e) => {
          return apiTool.getVipPrice(e)
        })
        // this.detail.nativePickupList = result.nativePickupList ? result.nativePickupList.map(e => e.pickupId) : []
        this.detail.deliveryStatus = result.deliveryStatus ? result.deliveryStatus.split(',') : ['0', '1']
        this.pickupAddressList = result.pickupAddressList
          ? result.pickupAddressList.map((d) => ({ name: d.name, value: d.id }))
          : []
        this.detail.pickupAddressList = result.pickupAddressList ? result.pickupAddressList.map((d) => d.id) : []
        this.$forceUpdate()
      })

    getAction('/api/base/farmPickupAddress/list').then((res) => {
      this.pickupAddressList = res.data.map((d) => ({ name: d.name, value: d.id }))
    })

    api.command.getLabelCodeGroup.call(this, {
      url: '/api/base/farmLabelData/getByCodeSelf',
      params: {
        id: id ?? '',
        codes: [
          {
            show: true,
            key: 'product_label',
          },
          {
            show: appCode === 'nqb',
            key: 'farm_vip',
          },
        ].filter((e) => e.show),
      },
    })
    // getAction('/api/base/farmPickupAddress/list').then(res => {
    //   if (res.code == 200) {
    //     let arr = res.data
    //     this.goodsLocation = arr.map(e => {
    //       return {
    //         ...e,
    //         value: e.id
    //       }
    //     })
    //   } else {
    //     this.$message.warning(res.msg)
    //   }
    // })
  },
  methods: {
    getMainBody(id) {
      getAction(`/api/baseMonitor/queryBaseMonitorList?shopId=${id}`).then((e) => {
        this.isShowSnapshot = e.data.length > 0 ? true : false
      })
    },
    async mallSearch(value = '', type = false) {
      let searchResult = await api.command.search.call(this, {
        url: `/api/config/farmGoodsCategory/list?shopId=${this.detail.linkShopId || ''}&name=${value}`,
      })
      this.mallData = searchResult.map((el) => {
        return {
          custom: el.type === '1',
          name: el.name,
          value: el.id,
        }
      })
    },
    async thirdProductSearch() {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmNativeProduct/queryProduct?shopId=${this.detail.linkShopId}`,
      })
      this.thirdProductData = searchResult.map((el) => {
        return {
          ...el,
          name: el.name,
          value: `${el.id}`,
        }
      })
    },
    async thirdBatchSearch() {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmNativeProduct/queryBatch?productId=${this.detail.thirdProductId}`,
      })

      this.thirdBatchData = searchResult.map((el) => {
        return {
          ...el,
          name: el.name,
          value: `${el.id}`,
        }
      })
    },
    async storeSearch(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmMainBody/list?mainBodyName=${value}`,
      })
      this.storeData = searchResult.map((el) => {
        return {
          name: el.mainBodyName,
          value: el.id,
        }
      })
    },
    linkShopSelect(item) {
      if (!this.$route.query.id) {
        getAction(`/api/commodity/farmMainBody/${item.value}`).then((res) => {
          const result = res.data.pickupAddressId
          this.pickupAddressList = this.pickupAddressList.filter((d) => result.indexOf(d.value) > -1)
        })
      }
    },
    async freightSearch(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/config/farmFreight/list?name=${value}`,
      })
      this.freightData = searchResult.map((el) => {
        return {
          name: el.name,
          value: el.id,
        }
      })
    },
    getVipPic(pic1, pic2, index) {
      let price = Math.round((pic2 + (index * (pic1 - pic2)) / 6) * 100) / 100
      return price
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '特产名称(76字符内)',
            type: 'input',
            cols: 12,
            key: 'nativeProductName',
            props: {
              maxLength: 76,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '关联店铺',
            type: 'dropDownInput',
            cols: 12,
            key: 'linkShop',
            onInputSearch: this.storeSearch,
            onMenuClick: this.linkShopSelect,
            valueKey: 'linkShopId',
            inputProps: {
              placeholder: '搜索店铺',
              addonBefore: <a-icon type="search" />,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '商品目录',
            type: 'dropDownInput',
            cols: 10,
            key: 'categories',
            onInputSearch: this.mallSearch,
            valueKey: 'categoriesId',
            inputProps: {
              placeholder: '搜索商品分类',
              addonBefore: <a-icon type="search" />,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            type: 'button',
            cols: 2,
            label: '管理分类',
            props: {
              style: {
                marginTop: '43px',
              },
              type: 'primary',
              ghost: true,
              on: {
                click: () => {
                  if (this.detail.linkShop && this.detail.linkShopId) {
                    getAction('/config/farmGoodsCategory/customList', { shopId: this.detail.linkShopId }, '/api').then(
                      (res) => {
                        let { data } = res
                        this.classifyList = data
                        this.showManageClassify(data)
                      }
                    )
                  } else {
                    this.$message.warning('请先选择关联店铺')
                  }
                },
              },
            },
          },
          //   {
          //     name: '运费模板',
          //     type: 'row',
          //     cols: 12,
          //     gutter: 3,
          //     rules: [
          //       {
          //         required: true
          //       }
          //     ],
          //     children: [
          //       {
          //         type: 'select',
          //         cols: 16,
          //         placeholder: '请选择运费模板',
          //         key: 'freightTemplateId',
          //         typeData: this.freightData,
          //         rules: [
          //           {
          //             required: true,
          //             type: 'string'
          //           }
          //         ]
          //       },
          //       {
          //         type: 'button',
          //         cols: 4,
          //         label: '编辑运费模板',
          //         props: {
          //           style: {
          //             margintTop: '10px'
          //           },
          //           on: {
          //             click: () => {
          //               this.editYModule()
          //             }
          //           }
          //         }
          //       },
          //       {
          //         cols: 4,
          //         type: 'button',
          //         label: '刷新运费模板',
          //         props: {
          //           style: {
          //             margintTop: '10px'
          //           },
          //           on: {
          //             click: () => this.freightSearch()
          //           }
          //         }
          //       }
          //     ]
          //   },
          {
            name: '品牌名称',
            type: 'input',
            cols: 12,
            key: 'brandName',
            props: {
              maxLength: 50,
            },
          },
          {
            name: '排序',
            type: 'input',
            cols: 12,
            key: 'sort',
            props: {
              maxLength: 50,
            },
          },
          {
            name: '商品类型',
            type: 'radioButton',
            key: 'productType',
            cols: 12,
            defaultValue: '0',
            typeData: productTypeSpecialtyList,
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: 'Banner选择',
            type: 'radioButton',
            key: 'bannerType',
            cols: 12,
            defaultValue: '0',
            typeData: [
              {
                name: 'banner图片',
                value: '0',
                display: true,
              },
              {
                name: '视频地址',
                value: '2',
                display: true,
              },
              {
                name: '数字基地监控视频',
                value: '1',
                display: this.isShowSnapshot,
              },
            ].filter((e) => e.display),
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '开放购买（默认选择是）',
            type: 'radioButton',
            key: 'canBuy',
            cols: 12,
            typeData: [
              {
                name: '是',
                value: '1',
              },
              {
                name: '否',
                value: '0',
              },
            ],
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '周边推荐（默认选择否）',
            type: 'radioButton',
            key: 'recommend',
            defaultValue: '0',
            cols: 12,
            typeData: [
              {
                name: '是',
                value: '1',
              },
              {
                name: '否',
                value: '0',
              },
            ],
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '购买须知',
            type: 'rich',
            key: 'buyNotice',
            cols: 24,
            maxLength: 1000,
            rules: [
              {
                message: '请输入',
              },
            ],
          },
        ],
      }
    },
    getForm11() {
      return {
        title: '配送',
        type: 'cardForm',
        data: [
          {
            name: '配送方式',
            type: 'checkBoxButton',
            key: 'deliveryStatus',
            cols: 12,
            display: true,
            typeData: [
              {
                name: '物流配送',
                value: '0',
              },
              {
                name: '农业局提货点',
                value: '1',
              },
            ],
            rules: [
              {
                required: true,
                type: 'array',
              },
            ],
          },
          {
            name: '关联自提点',
            type: 'checkBoxButton',
            key: 'pickupAddressList',
            cols: 12,
            disabled: true,

            typeData: this.pickupAddressList,
            display: this.detail.deliveryStatus.includes('1'),
          },
          {
            name: '运费模板',
            type: 'row',
            cols: 12,
            gutter: 3,
            display: true,
            rules: [
              {
                message: '请选择运费模板',
                required: true,
              },
            ],
            children: [
              {
                type: 'select',
                cols: 16,
                placeholder: '请选择运费模板',
                key: 'freightTemplateId',
                typeData: this.freightData,
                rules: [
                  {
                    message: '请选择运费模板',
                    required: true,
                    type: 'string',
                  },
                ],
              },
              {
                type: 'button',
                cols: 4,
                label: '编辑运费模板',
                props: {
                  style: {
                    margintTop: '10px',
                  },
                  on: {
                    click: () => {
                      this.editYModule()
                    },
                  },
                },
              },
              {
                cols: 4,
                type: 'button',
                label: '刷新运费模板',
                props: {
                  style: {
                    margintTop: '10px',
                  },
                  on: {
                    click: () => this.freightSearch(),
                  },
                },
              },
            ],
          },
          //   {
          //     name: '添加农业局提货点',
          //     type: 'select',
          //     cols: 12,
          //     // display: this.detail.deliveryStatus.includes('1'),
          //     props: {
          //       mode: 'multiple'
          //     },
          //     rules: [
          //       {
          //         required: true,
          //         type: 'array'
          //       }
          //     ],
          //     key: 'nativePickupList',
          //     typeData: this.goodsLocation
          //   }
        ].filter((e) => e.display),
      }
    },
    showManageClassify(dataSource) {
      apiTool.showModal({
        width: '800px',
        title: '分类',
        success: ({ data, setHidden }) => {
          try {
            dataSource.forEach((e, i) => {
              if (!e.name || !e.sort) {
                throw new Error('请完善名称/排序！')
              }
            })
          } catch (error) {
            this.$message.warning('请完善名称/排序！')
            return false
          }
          let obj = {
            shopId: this.detail.linkShopId,
            customDTOS: dataSource.map((e) => {
              return {
                ...e,
                shopId: this.detail.linkShopId,
              }
            }),
          }
          postAction('/config/farmGoodsCategory/addBatchCustom', obj).then((res) => {
            if (res.code == 200) {
              this.$message.success('保存成功！')
              setHidden()
            } else {
              this.$message.error('保存失败！')
            }
          })
        },
        form: [
          {
            type: 'table',
            wrapperCol: { span: 24 },
            showFootButton: true,
            showRowSelect: false,
            showPagination: false,
            // table新增按钮回调
            onAddData: () => {
              return {
                name: null,
                sort: 0,
                productCount: 0,
              }
            },
            columns: [
              {
                dataIndex: 'name',
                align: 'left',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>名称
                  </div>
                ),
                width: 240,
                customRender: function (text, records, index) {
                  const onInput = (data) => {
                    records.name = data.target.value
                  }
                  return <a-input placeholder="名称" onChange={onInput} value={text} />
                },
              },
              {
                dataIndex: 'sort',
                align: 'left',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>排序
                  </div>
                ),
                width: 100,
                customRender: function (text, records) {
                  const onInput = (data) => {
                    records.sort = data.target.value
                  }
                  return <a-input type="number" placeholder="" onChange={onInput} value={text} />
                },
              },
              {
                dataIndex: 'productCount',
                align: 'left',
                title: '已关联商品',
                width: 80,
                customRender: function (text, records) {
                  return <div>{text}</div>
                },
              },
              {
                align: 'center',
                width: '10%',
                title: '操作',
                tableType: 'editDelete',
                unShowEditBtn: true,
                disabledKey: 'productCount',
              },
            ],
            dataSource: dataSource,
          },
        ],
      })
    },
    editYModule() {
      let a = document.createElement('a')
      a.setAttribute('href', '/platform/freight')
      a.setAttribute('target', '_blank')
      a.setAttribute('id', 'startTelMedicine')
      // 防止反复添加
      if (document.getElementById('startTelMedicine')) {
        document.body.removeChild(document.getElementById('startTelMedicine'))
      }
      document.body.appendChild(a)
      a.click()
    },
    getFormBox() {
      return {
        type: 'cardFormBox',
        children: [this.getForm2(), this.getForm23()],
      }
    },
    getForm2() {
      return {
        type: 'cardForm',
        title: () => (
          <div>
            <span style={{ color: '#f5222d' }}>*</span>Banner（尺寸750*420PX）限制20张
          </div>
        ),
        data: [
          {
            type: 'uploadDraggable',
            maxLength: 20,
            key: 'bannerUrl',
            cols: 24,
            multiple: true,
            rules: [
              {
                required: true,
                message: '请上传图片',
                type: 'string',
              },
            ],
          },
        ],
      }
    },
    getForm23() {
      return {
        type: 'cardForm',
        title: '宣传视频',
        data: [
          {
            type: 'iconUploadVideo',
            typeData: [
              {
                maxLength: 1,
                key: 'videoUrl',
                desc: '视频上传尺寸<20M <br /> 支持格式.mov/.mp4/.wmv/.avi',
              },
            ],
          },
        ],
      }
    },
    getForm24() {
      return {
        type: 'cardForm',
        title: '数字基地监控视频',
        data: [this.isShowSnapshot ? this.getBaseDataInit(this.detail.linkShopId) : []],
      }
    },
    getBaseDataInit(shopId = null) {
      return {
        type: 'baseUpload',
        key: 'monitorCode',
        maxLength: 1,
        cols: 24,
        shopId: shopId,
        setValue: this.detail.monitorCode,
        setFirm: false,
      }
    },
    getFormBox1() {
      if (!this.detail.linkShopId) return
      return {
        type: 'cardFormBox',
        children: [this.getStandard(), this.getProduct()],
      }
    },
    getStandard(shopId = this.detail.linkShopId) {
      let that = this
      if (!that.detail.linkShopId) return
      return {
        type: 'cardFormRow',
        title: '三品一标',
        children: [
          <FormProductStandard
            ref="product1"
            item={{
              shopId: shopId,
              typeList: 1,
              selectArr: that.detail.certThreeUrls,
            }}
          />,
        ],
      }
    },
    getProduct(shopId = this.detail.linkShopId) {
      let that = this
      if (!that.detail.linkShopId) return
      return {
        type: 'cardFormRow',
        title: '检验合格证',
        children: [
          <FormProductStandard
            ref="product2"
            item={{
              shopId: shopId,
              typeList: 2,
              selectArr: that.detail.otherInspectionUrls,
            }}
          />,
        ],
      }
    },
    getForm5() {
      return {
        title: '农产品追溯—关联',
        type: 'cardForm',
        data: [
          {
            name: '产品名称',
            type: 'select',
            cols: 12,
            key: 'thirdProductId',
            typeData: this.thirdProductData,
            props: {
              placeholder: '选择产品名称',
              allowClear: true,
            },
          },
          {
            name: '产品批次号',
            type: 'select',
            cols: 12,
            key: 'thirdBatchId',
            typeData: this.thirdBatchData,
            props: {
              placeholder: '产品批次号',
              allowClear: true,
            },
          },
        ],
      }
    },
    getForm3() {
      let that = this
      return {
        type: 'table',
        showFootButton: true,
        showRowSelect: false,
        showPagination: false,
        // table新增按钮回调
        onAddData: () => {
          return {
            skuImgUrl: '',
            standardsName: null,
            stockNum: null,
            salePrice: null,
            retailPrice: null,
            retailPriceMin: null,
            retailPriceMax: null,
            vipPrice: null,
            crossedPrice: null,
            v1: '-',
            v2: '-',
            v3: '-',
            v4: '-',
            v5: '-',
            v6: '-',
          }
        },
        columns: [
          {
            title: '图片',
            type: 'lt-40',
            dataIndex: 'skuImgUrl',
            customRender: (text, records, index, h) => {
              return (
                <FormUploadSku
                  value={text}
                  onChange={(data) => {
                    records.skuImgUrl = data
                  }}
                />
              )
            },
          },
          {
            dataIndex: 'standardsName',
            align: 'left',
            title: <div style={{ marginLeft: '20px' }}>产品规格</div>,
            width: 200,
            customRender: function (text, records, index) {
              const onInput = (data) => {
                records.standardsName = data.target.value
              }
              return records.isEdit ? (
                <a-input disabled={that.detail.canBuy == '0'} placeholder="产品规格" onChange={onInput} value={text} />
              ) : (
                <div style={{ marginLeft: '20px' }}>{text}</div>
              )
            },
          },
          {
            dataIndex: 'stockNum',
            align: 'left',
            title: '库存',
            width: 100,
            customRender: function (text, records, index) {
              const onInput = (data) => {
                records.stockNum = data.target.value
              }
              return records.isEdit ? (
                <a-input
                  disabled={that.detail.canBuy == '0'}
                  type="number"
                  placeholder="库存数量"
                  onChange={onInput}
                  value={text}
                />
              ) : (
                <div>{text}</div>
              )
            },
          },
          {
            dataIndex: 'crossedPrice',
            align: 'left',
            title: '划线价',
            width: 100,
            customRender: function (text, records, index) {
              const onInput = (data) => {
                records.crossedPrice = data.target.value
              }
              return records.isEdit ? (
                <a-input
                  disabled={that.detail.canBuy == '0'}
                  type="number"
                  addon-before="￥"
                  placeholder="划线价"
                  onChange={onInput}
                  value={text}
                />
              ) : (
                <div>{text}</div>
              )
            },
          },
          {
            dataIndex: 'salePrice',
            align: 'left',
            title: '现价',
            width: 100,
            customRender: function (text, records) {
              const onInput = (data) => {
                records.salePrice = data.target.value
              }
              return records.isEdit ? (
                <a-input
                  disabled={that.detail.canBuy == '0'}
                  type="number"
                  addon-before="￥"
                  placeholder="价格"
                  onChange={onInput}
                  value={text}
                />
              ) : (
                <div>{text}</div>
              )
            },
          },
          {
            dataIndex: 'buyCount',
            align: 'left',
            title: '限购(0表示不限量)',
            width: 150,
            customRender: function (text, records) {
              const onInput = (data) => {
                records.buyCount = data.target.value
              }
              return records.isEdit ? (
                <a-input
                  disabled={that.detail.canBuy == '0'}
                  type="number"
                  placeholder="限量"
                  onChange={onInput}
                  value={text || 0}
                  min={0}
                />
              ) : (
                <div>{text || 0}</div>
              )
            },
          },
          {
            dataIndex: 'retailPrice',
            align: 'left',
            title: '分销价',
            width: 100,
            customRender: function (text, records) {
              const onInput = (data) => {
                records.retailPrice = data.target.value
              }
              return records.isEdit ? (
                <a-input
                  disabled={that.detail.canBuy == '0'}
                  type="number"
                  addon-before="￥"
                  placeholder="价格"
                  onChange={onInput}
                  value={text}
                />
              ) : (
                <div>{text}</div>
              )
            },
          },
          {
            dataIndex: 'retailPriceMin',
            align: 'left',
            title: '最低价最高价',
            width: 210,
            customRender: function (text, records) {
              const onInput = (data) => {
                records.retailPriceMin = data.target.value
              }
              const onInput2 = (data) => {
                records.retailPriceMax = data.target.value
              }
              return records.isEdit ? (
                <div>
                  <a-input
                    disabled={that.detail.canBuy == '0'}
                    type="number"
                    addon-before="￥"
                    placeholder="价格"
                    onChange={onInput}
                    value={records.retailPriceMin}
                    style={{ width: '100px' }}
                  />
                  <span style={{ lineHeight: '32px' }}> ~ </span>
                  <a-input
                    disabled={that.detail.canBuy == '0'}
                    type="number"
                    addon-before="￥"
                    placeholder="价格"
                    onChange={onInput2}
                    value={records.retailPriceMax}
                    style={{ width: '100px' }}
                  />
                </div>
              ) : (
                <div>
                  {records.retailPriceMin} ~ {records.retailPriceMax}
                </div>
              )
            },
          },
          {
            dataIndex: 'vipPrice',
            align: 'left',
            title: '会员最低价',
            width: 180,
            customRender: function (text, records) {
              const onInput = (data) => {
                records.vipPrice = data.target.value
              }
              return records.isEdit ? (
                <a-input
                  disabled={that.detail.canBuy == '0'}
                  type="number"
                  value={text}
                  addon-before="￥"
                  placeholder="会员价必填且不能为0"
                  onChange={onInput}
                />
              ) : (
                <div>{text}</div>
              )
            },
          },
          {
            dataIndex: 'v1',
            align: 'left',
            title: 'V1',
            width: 50,
          },
          {
            dataIndex: 'v2',
            align: 'left',
            title: 'V2',
            width: 50,
          },
          {
            dataIndex: 'v3',
            align: 'left',
            title: 'V3',
            width: 50,
          },
          {
            dataIndex: 'v4',
            align: 'left',
            title: 'V4',
            width: 50,
          },
          {
            dataIndex: 'v5',
            align: 'left',
            title: 'V5',
            width: 50,
          },
          {
            dataIndex: 'v6',
            align: 'left',
            title: 'V6',
            width: 50,
          },
          {
            dataIndex: 'a10',
            align: 'center',
            width: '10%',
            title: '操作',
            tableType: 'editDelete',
            onSave: (text, records) => {
              if (records.standardsName && records.stockNum >= 0 && records.salePrice > 0) {
                if (Number(records.salePrice) < Number(records.vipPrice)) {
                  this.$message.warning('会员价应低于现价！')
                } else if (records.vipPrice != '' && Number(records.vipPrice) == 0) {
                  this.$message.warning('会员价不能为零！')
                } else {
                  records.isEdit = false
                  apiTool.getVipPrice(records)
                }
              } else {
                this.$message.warning('请填入产品规格/库存（不能小于零）/现价（不能为零）')
              }
            },
          },
        ],
        dataSource: this.detail.farmNativeProductStandardsVOS,
      }
    },
    getLabelForm(index) {
      return [
        {
          name: '标签名称',
          type: 'input',
          key: 'labelName',
        },
        {
          name: '标签类型',
          type: 'radioButton',
          key: 'publicLabel',
          typeData: [
            {
              name: '店铺私有',
              value: '2',
            },
            {
              name: '公用标签库',
              value: '1',
            },
          ],
        },
        index == 3 && {
          name: '标签分类',
          type: 'iconClass',
          key: 'iconUrl',
        },
      ].filter((e) => e)
    },
    getLabelGroup() {
      return {
        type: 'row',
        children: (this.labelGroup || []).map((e, i) => {
          return (
            <CardIconLabelLocal
              data={this.getLabelForm(i)}
              title={e.labelTypeName}
              pubBool={false}
              isAddBtn={e.labelTypeCode !== 'farm_vip'}
              cols={24}
              style={{
                marginBottom: '10px',
                minHeight: '200px',
              }}
              icons={e.list}
            />
          )
        }),
      }
    },
    getForm4() {
      return {
        type: 'cardForm',
        cols: 24,
        data: [
          {
            type: 'rich',
            key: 'content',
            cols: 24,
          },
        ],
      }
    },
    getChildrenRecursive({ arr, code }) {
      let result
      arr.forEach((item) => {
        if (item[id] === code) {
          result = item
        } else {
          this.getChildrenRecursive({
            arr: item[children],
            code,
          })
        }
      })
      return result
    },
    goodsSendC(idList) {
      let arr = []
      this.goodsLocation.forEach((element) => {
        if (idList.includes(element.id)) {
          arr.push({
            id: null,
            pickupId: element.id,
            pickupName: element.name,
            productId: null,
          })
        }
      })
      return arr
    },
    submit(data) {
      //   let nativePickupList = data.nativePickupList.length > 0 ? this.goodsSendC(data.nativePickupList) : []
      let that = this
      let goodsTypeName = document.getElementsByClassName('ant-select-selection-selected-value')[0].innerHTML
      if (data.canBuy == 1) {
        if (data.farmNativeProductStandardsVOS.length == 0) {
          this.$message.warning('请输入产品规格！')
          return
        } else {
          try {
            data.farmNativeProductStandardsVOS.forEach((element) => {
              if (element.isEdit) {
                this.$message.warning('请先保存产品规格！')
                throw new Error('结束！')
              }
            })
          } catch (error) {
            console.log(error)
            return
          }
        }
      }

      let monitorCode = null
      let monitorImg = null
      if (data.monitorCode instanceof Array && data.bannerType == 1 && data.monitorCode.length > 0) {
        monitorCode = (
          data.monitorCode.find((e) => e.select) || {
            monitorCode: '',
          }
        ).monitorCode
        monitorImg = (
          data.monitorCode.find((e) => e.select) || {
            monitorImg: '',
          }
        ).monitorImg
      }

      // 获取标签
      let arr = []
      this.labelGroup.forEach((item) =>
        item.list.forEach((e) => {
          e['labelTypeCode'] = e.labelTypeCode || item.labelTypeCode
          e['labelTypeId'] = item.id
          e['labelTypeName'] = item.labelTypeName
        })
      )
      this.labelGroup.map((e) => arr.push(...e.list))
      let brr = arr.map((e) => ({
        ...e,
        czState: e.czState ? 1 : 0,
      }))

      let farmNativeProductStandardsDTOS = that.detail.farmNativeProductStandardsVOS.map((e) => {
        return {
          id: e.id,
          salePrice: e.salePrice,
          standardsName: e.standardsName,
          stockNum: e.stockNum,
          vipPrice: e.vipPrice,
          nativeProductId: e.nativeProductId,
          skuImgUrl: e.skuImgUrl,
          retailPrice: e.retailPrice,
          retailPriceMax: e.retailPriceMax,
          retailPriceMin: e.retailPriceMin,
          crossedPrice: e.crossedPrice,
          buyCount: e.buyCount || 0,
        }
      })

      if (data.freightTemplateId) {
        that.freightData.forEach((e) => {
          if (e.value == data.freightTemplateId) {
            data.freightTemplate = e.name
          }
        })
      } else {
        data.freightTemplateId = ''
        data.freightTemplate = ''
      }

      if (data.thirdProductId) {
        that.thirdProductData.forEach((e) => {
          if (e.value == data.thirdProductId) {
            data.thirdProductName = e.name
          }
        })
      } else {
        data.thirdProductId = ''
        data.thirdProductName = ''
      }

      let upDown = data.id ? data.upDown : '0'
      let otherInspectionUrlsArr = this.$refs.product2.activityBackground.filter((e) => e.isOpen).map((e) => e.image2)
      let certThreeUrlsArr = this.$refs.product1.activityBackground.filter((e) => e.isOpen).map((e) => e.image2)

      this.$delete(data, 'farmNativeProductStandardsVOS')
      api.command[data.id ? 'edit' : 'create']
        .call(this, {
          url: '/commodity/farmNativeProduct',
          params: {
            ...data,
            farmNativeProductStandardsDTOS: farmNativeProductStandardsDTOS,
            upDown: upDown,
            monitorCode: monitorCode,
            monitorImg: monitorImg,
            goodsTypeName: goodsTypeName,
            // nativePickupList,
            deliveryStatus: data.deliveryStatus.length > 0 ? data.deliveryStatus.toString() : '',
            otherInspectionUrls: otherInspectionUrlsArr.length > 0 ? otherInspectionUrlsArr.toString() : '',
            farmLabelDataDTOS: brr,
            certThreeUrls: certThreeUrlsArr.length > 0 ? certThreeUrlsArr.toString() : '',
          },
          isPost: false,
        })
        .then((result) => {
          this.$router.push('/commodityManagement/specialty')
        })
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: this.submit,
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            this.$router.push('/commodityManagement/specialty')
          },
        },
      ]
      const right = [
        {
          name: ` ${this.detail.upDown == 0 ? '下架' : '上架'} `,
          type: 'default',
          onClick: () => {
            putAction(`/commodity/farmNativeProduct/${this.detail.upDown == 0 ? 'down' : 'up'}/${this.detail.id}`).then(
              (e) => {
                if (e.code == 200) {
                  this.$message.success(`${this.detail.upDown == 0 ? '下架成功' : '上架成功'}`)
                  setTimeout(() => {
                    this.$router.back()
                  }, 500)
                } else {
                  this.$message.error(e.msg)
                }
              }
            )
          },
        },
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.del.call(this, {
              url: `/commodity/farmNativeProduct/${this.detail.id}`,
              isPost: false,
            })

            setTimeout(() => {
              this.$router.back()
            }, 500)
          },
        },
      ]
      return {
        left,
        right: this.detail.id && right,
      }
    },
  },
  render() {
    return (
      <div>
        <rich-swiper-view></rich-swiper-view>
        <DetailFormGroup
          typeData={{
            linkShop: this.storeData,
            categories: this.mallData,
          }}
          foot={this.getFoot()}
          form={this.detail}
          data={[
            this.getForm1(),
            this.getForm11(),
            this.getForm2(),
            this.getForm23(),
            this.getFormBox1(),
            this.isShowSnapshot && this.getForm24(),
            this.getForm5(),
            this.getForm3(),
            this.getLabelGroup(),
            this.getForm4(),
          ]}
        />
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

/deep/.ant-input[type='number'] {
  -moz-appearance: textfield;
}

/deep/.ant-input[type='number']::-webkit-inner-spin-button,
/deep/.ant-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
